<template>
  <c-box w="full">
    <Breadcrumb :items="breadcrumbItems" />
    <hr v-chakra mt="20px" />
    <c-text mt="30px" font-size="24px" font-weight="700" line-height="36px">
      Tambah Glossary
    </c-text>
    <CreateForm ref="$forms" v-model="glossary" v-chakra mt="30px" />
    <c-stack mt="30px" is-inline spacing="20px">
      <c-button
        type="button"
        rounded="full"
        w="289px"
        h="62px"
        as="router-link"
        :to="{ name: 'admin.glossary' }"
      >
        Batal
      </c-button>
      <c-button
        type="button"
        rounded="full"
        w="289px"
        h="62px"
        variant-color="brand"
        :is-disabled="isLoading || isInvalid"
        :disabled="isLoading || isInvalid"
        @click.prevent="submit()"
      >
        {{isLoading ? 'Menyimpan...' : 'Simpan'}}
      </c-button>
    </c-stack>
  </c-box>
</template>

<script>
import CreateForm from "./forms.vue";
import Breadcrumb from "@/components/breadcrumb";

export default {
  name: "SUGlossaryCreate",
  components: { Breadcrumb, CreateForm },
  data() {
    return {
      glossary: null,
      isInvalid: true,
      isLoading: false,
    };
  },
  computed: {
    breadcrumbItems() {
      return [
        {
          label: "Manajemen Glossary",
          href: this.$router.resolve({ name: "admin.glossary" }).href,
        },
        {
          label: "Tambah Glossary",
          isCurrent: true,
        },
      ];
    },
  },
  methods: {
    async submit() {
      if (this.glossary == null || this.isInvalid) return;

      try {
        this.isLoading = true;

        if (this.glossary.photoFile != null) {
          this.glossary.photoUrl = await this.$store.dispatch(
            "admGlossary/upload",
            this.glossary.photoFile
          );
        }
        this.$store.dispatch("admGlossary/create", this.glossary).then((it) => {
          this.$router.replace({
            name: "admin.glossary-detail",
            params: {
              glossaryId: it.id,
            },
          });
        });
      } catch (e) {
        this.$toast({
          title: "Failed",
          description: e?.data?.message ?? e.toString(),
          status: "error",
          duration: 5000,
          position: "bottom-right",
          variant: "subtle",
        })
      } finally {
        this.isLoading = false;
      }

    },
  },
  updated() {
    this.isInvalid = this.$refs.$forms?.$v.$invalid;
  },
};
</script>

<style scoped></style>
